import React, { useEffect, useState, Suspense } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import { getDatosUser } from "./login/services/getUser.js";
import BarraInfo from "../components/BarraInfo.jsx";
import { nvl } from "../utilities/index.js";

function Layout({ menu, mods }) {
  const [userData, setUserData] = useState([]);
  const idMenu = useParams().mod;
  const navigate = useNavigate();
  const ITEM_VISIBILITY = 1;
  const sys_name = mods.filter((item) => item.ID === idMenu);
  const menuUser = menu.filter((item) => item.MENU === idMenu);
  if (!menuUser) navigate("/", { replace: true });

  const generateIconUser = () => {
    const letter = document.getElementById("user-info-letter");
    const user = userData?.nick || false;
    if (user && letter) {
      //const nombre_apellido = user.split(", ");
      letter.textContent = nvl(user[0], "").trim().toUpperCase(); // nombre_apellido[1][0].trim() + nombre_apellido[0][0].trim();
    }
  };

  useEffect(() => {
    const loggedUserToken = window.localStorage.getItem("keyToken");
    const jsonToken = JSON.parse(loggedUserToken);

    Promise.all([getDatosUser(jsonToken?.id)]).then((res) => {
      const DatosUser = res[0];
      setUserData(DatosUser);
    });
  }, []);

  generateIconUser();
  const ItemsMenu = [];
  menuUser.forEach((item) => {
    if (item.VISIBLE === ITEM_VISIBILITY) {
      ItemsMenu.push({
        url: item.PATHNAME,
        iconName: item.ICONO,
        title: item.DESCRIPCION,
      });
    }
  });

  ItemsMenu.unshift({
    url: "/",
    iconName: faHouseChimney,
    title: "Inicio",
  });

  return (
    <div className="layout">
      <Sidebar
        menu={ItemsMenu}
        idMenu={idMenu}
        title={sys_name[0].DESCRIPCION}
      />

      <div className="content width-full-vh">
        {/* ======================================= */}
        <div>
          <BarraInfo data={userData}> </BarraInfo>
        </div>
        {/* ======================================= */}
        <div className="main-content">
          <Suspense>
            <Outlet context={userData} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default Layout;
